@import url("https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700&display=swap");
@font-face {
    font-family: "abeatbyKaiRegular";
    src: url("../src/assets/fonts/abeatbyKaiRegular.otf") format("opentype");
}
@import "~@angular/cdk/overlay-prebuilt.css";

/*================================================
Default CSS
=================================================*/
$main-color: #004745;
$white-color: #ffffff;
$black-color: #000000;
$grey-color: #787a8b;
$red-color: #c12228;
$paragraph-color: #828893;
$light-grey: #999999;
$font-family: "Poppins", sans-serif;
$font-family2: "Poppins", sans-serif;
$spl-font: "abeatbyKaiRegular", serif;
$font-size: 14px;
$transition: 0.5s;

html,
body {
    overflow-x: hidden;
    padding: 0;
    margin: 0;
    font: {
        size: $font-size;
        family: $font-family;
    }
}
a {
    text-decoration: none !important;
    transition: $transition;
    font: {
        family: $font-family;
    }
}
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
    color: $light-grey;
    margin-bottom: 15px;
    font: {
        family: $font-family;
    }
    &:last-child {
        margin-bottom: 0;
    }
}

.form-check-input:checked[type="checkbox"],
.form-check-input:checked {
    background-color: #004745 !important;
}
p {
    line-height: 1.8;
    margin-bottom: 15px;
    color: $paragraph-color;

    &:last-child {
        margin-bottom: 0;
    }
}
.ml-auto {
    margin-left: auto;
}
img {
    max-width: 100%;
    height: auto;
}
.mt-30 {
    margin-top: 30px;
}
.ptb-100 {
    padding: {
        top: 100px;
        bottom: 100px;
    }
    
}

.ptb-90 {
    padding: {
        top: 90px;
        bottom: 90px;
    }
    
}

:focus {
    outline: 0 !important;
}
.d-table {
    width: 100%;
    height: 100%;

    &-cell {
        vertical-align: middle;
    }
}
.alert {
    padding: 5px !important;
}
/*btn btn-primary*/
.btn {
    color: $white-color;
    // box-shadow: 0 8px 6px -6px rgba(50,51,51,.4);
    box-shadow: none !important;
    border: none;
    padding: 10px 40px;
    border-radius: 35px;
    transition: $transition;
    font: {
        weight: 600;
        size: $font-size;
    }
}
.btn-primary {
    color: $white-color;
    background: $main-color;
    position: relative;
    z-index: 1;
}
/*form-control*/
.form-control {
    height: 42px;
    padding: 15px;
    font-size: 14px;
    font-weight: 600;
    border: 1px solid #004745;
    border-radius: 0;
    transition: $transition;
    border-radius: 50px;

    &:focus {
        box-shadow: unset;
        border-color: $main-color;
    }
}
/*section-title*/
.section-title {
    text-align: center;
    position: relative;
    z-index: 1;
    margin: {
        // bottom: 3rem;
        // top: 3rem;
    }
    h3 {
        margin-bottom: 0;
        font: {
            size: 35px;
            weight: 800;
            color: $main-color;
        }
        span {
            color: $red-color;
        }
    }
    p {
        max-width: 520px;
        margin: 15px auto 0;
    }
}

.testi-con {
    margin-bottom: 3rem;
}

/*================================================
Preloader CSS
=================================================*/
.preloader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 999999;
    overflow: hidden;
    background: $black-color;

    .loader {
        display: block;
        position: relative;
        left: 50%;
        top: 50%;
        width: 110px;
        height: 110px;
        margin: -80px 0 0 -75px;
        border-radius: 50%;
        border: 7px solid transparent;
        border-top-color: $main-color;
        animation: spin 1.7s linear infinite;
        z-index: 11;

        &::before {
            content: "";
            position: absolute;
            top: 5px;
            left: 5px;
            right: 5px;
            bottom: 5px;
            border-radius: 50%;
            border: 7px solid transparent;
            border-top-color: $white-color;
            animation: spin-reverse 0.6s linear infinite;
        }
        &::after {
            content: "";
            position: absolute;
            top: 15px;
            left: 15px;
            right: 15px;
            bottom: 15px;
            border-radius: 50%;
            border: 7px solid transparent;
            border-top-color: $main-color;
            animation: spin 1s linear infinite;
        }
    }
}
@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
@keyframes spin-reverse {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(-360deg);
    }
}

/*================================================
Navbar CSS
=================================================*/
.navbar {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 9;
    padding: 25px 0 0 0;
    height: auto;

    &.is-sticky {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        padding: 15px 0;
        z-index: 999;
        box-shadow: 0px 0px 18px 1px rgba(0, 0, 0, 0.1);
        background-color: $white-color !important;
        animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
    }
}
.bg-light {
    background-color: transparent !important;
}
.navbar-brand {
    img {
        &:last-child {
            display: none;
        }
    }
}
.navbar-light {
    .navbar-nav {
        .nav-item {
            padding: {
                right: 13px;
                left: 13px;
            }
            .nav-link {
                color: $main-color;
                padding: 0;
                position: relative;

                font: {
                    weight: 400;
                }
                &:hover {
                    color: $red-color;
                }
                &.active {
                    color: $red-color;
                    font: {
                        weight: 600;
                    }
                }
            }
            &:last-child {
                padding-right: 0;
            }
            &:first-child {
                padding-left: 0;
            }
            &.active {
                .nav-link {
                    color: $main-color;

                    &::before {
                        bottom: -13px;
                        opacity: 1;
                        visibility: visible;
                    }
                }
            }
        }
    }
    &.is-sticky {
        .navbar-brand {
            img {
                &:last-child {
                    display: block;
                }
                &:first-child {
                    display: none;
                }
            }
        }
        .navbar-nav {
            .nav-item {
                .nav-link {
                    color: $main-color;

                    &::before {
                        display: none;
                    }
                    &:hover,
                    &.active {
                        color: $red-color;

                        &::before {
                            bottom: auto;
                            top: -10px;
                        }
                    }
                }
                &.active {
                    .nav-link {
                        color: $main-color;

                        &::before {
                            bottom: auto;
                            top: -10px;
                        }
                    }
                }
            }
        }
    }
}
.navbar-light {
    .navbar-toggler {
        .navbar-toggler-icon {
            background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
        }
    }
    &.is-sticky {
        .navbar-toggler {
            .navbar-toggler-icon {
                background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0, 0, 0, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
            }
        }
    }
}

.rightnav {
    float: right;
    list-style: none;
    margin: 0px;
    padding: 0px;
    display: flex;
    li {
        a.nav-link {
            color: #c12228;
            padding-top: 0.6rem;
            font-weight: bold;
            width: 3rem;
            margin-right: 1rem;
            &:hover {
                color: #004745;
            }
        }
    }
}

// Mobile and iPad Navbar
@media only screen and (max-width: 991px) {
    .navbar {
        padding: {
            top: 15px;
            bottom: 15px;
        }
    }
    .navbar-expand-lg {
        > .container {
            padding: {
                left: 15px;
                right: 15px;
            }
        }
    }
    .navbar-light {
        .navbar-toggler {
            border-color: $main-color;
            box-shadow: unset;
            border-radius: 0;
            background-color: $main-color;;
        }
        .navbar-nav {
            background-color: $white-color;
            padding: 10px 20px;
            margin-top: 10px;

            .nav-item {
                padding: {
                    left: 0;
                    right: 0;
                    top: 10px;
                    bottom: 10px;
                }
                .nav-link {
                    color: $black-color;
                    display: block;

                    &.active,
                    &:hover {
                        color: $main-color;
                    }
                    &.active {
                        &::before {
                            bottom: 50%;
                        }
                    }
                    &::before {
                        margin: {
                            bottom: -5px;
                            right: 0;
                        }
                    }
                }
            }
        }
        &.is-sticky {
            padding: {
                top: 15px;
                bottom: 15px;
            }
            .navbar-toggler {
                border-color: $black-color;
                box-shadow: unset;
            }
            .navbar-nav {
                border: 1px solid #eeeeee;

                .nav-item {
                    .nav-link {
                        &.active {
                            &::before {
                                top: auto;
                                bottom: 50%;
                                display: block;
                            }
                        }
                    }
                }
            }
        }
    }
}

.main-hero {
    padding-top: 150px;
    min-height: 28rem;
    vertical-align: middle;
    align-items: center;
    // text-transform: capitalize;
    h1.main-title {
        font-size: 2.8rem;
        color: $red-color;
        font-weight: bold;
        span {
            color: $main-color;
            font-weight: 300;
            // text-transform: lowercase;
            font-family: $spl-font;
            font-size: 3.4rem;
        }
    }
    h2.sub-title {
        font-weight: bold;
        font-size: 2.8rem;
        color: #004745;
    }
    p {
        width: 80%;
    }
}
// #products{
//     margin:5rem 0 10rem;
// }
.products-main {
    padding-top: 150px;
    min-height: 28rem;
    vertical-align: middle;
    align-items: center;
    h4 {
        color: #65677b;
        font-size: 2.6rem;
        font-weight: 400;
    }
    h2 {
        color: $main-color;
        font-weight: bold;
        font-size: 4rem;
        span {
            color: $red-color;
            display: block;
        }
    }
    p {
        color: #65677b;
        line-height: auto;
        i {
            margin-right: 0.5rem;
        }
    }
}

.pro-con {
    padding-top: 200px;
    background: {
        image: url(assets/img/hexagon-bg.jpeg);
        position: right top;
        repeat: no-repeat;
        size: 36%;
    }
    h3 {
        font-weight: bold;
        font-size: 2.4rem;
        color: #234a49;
        margin-bottom: 1.5rem;
        span {
            color: $red-color;
        }
    }
    h6 {
        text-transform: uppercase;
        color: #65677b;
        font-size: 1.1rem;
        letter-spacing: 4px;
        margin-bottom: 0.5rem;
    }
    p {
        margin-bottom: 5rem;
    }

    img {
        text-align: center;
        margin: 3rem auto 1rem;
        display: flex;
        align-items: center;
        cursor: pointer;
    }
}
/*================================================
Main Banner CSS
=================================================*/
.main-banner {
    max-height: 100vh;
    position: relative;
    // z-index: 1;
    // background: {
    //     position: center center;
    //     size: cover;
    //     repeat: no-repeat;
    // };
    // &::before {
    //     content: '';
    //     position: absolute;
    //     left: 0;
    //     top: 0;
    //     width: 100%;
    //     height: 100%;
    //     z-index: -1;
    //     background: rgba(13, 15, 20, .55);
    // }
}
.video-banner {
    overflow: hidden;

    .video-background {
        position: absolute;
        left: 0;
        top: 0;
        width: auto;
        height: auto;
        min-height: 100%;
        min-width: 100%;
        z-index: -2;
    }
}
.ripple-effect {
    &.main-banner {
        &::before {
            z-index: 1;
        }
        .main-banner-content {
            position: relative;
            z-index: 2;
        }
    }
}
.item-bg1 {
    background-image: url(assets/img/reg-bg.png);
    background-position: -36px -74px;
    background-repeat: no-repeat;
    background-size: 90%;
    background-color: #f7f7f7;
}

.main-banner-content {
    max-width: 700px;
    position: relative;
    z-index: 3;
    margin: 60px 0 0;

    &.text-center {
        margin: 60px auto 0;

        p {
            margin: {
                left: auto;
                right: auto;
            }
        }
    }
    span {
        color: $white-color;
        text-transform: uppercase;
        display: block;
        letter-spacing: 2px;
        opacity: 0.88;
        font: {
            weight: 500;
            size: 16px;
        }
    }
    h1 {
        color: $white-color;
        margin: 20px 0 25px;
        font: {
            size: 55px;
            family: $font-family;
            weight: 300;
        }
        b {
            background: linear-gradient(
                to bottom,
                rgba(0, 0, 0, 0) 80%,
                $main-color 80%
            );
            background-size: 100px 1.2em !important;
            font: {
                weight: 700;
                family: $font-family;
            }
        }
        .typewrite {
            background: linear-gradient(
                to bottom,
                rgba(0, 0, 0, 0) 80%,
                $main-color 80%
            );
            background-size: 100px 1em !important;
            display: inline-block;
            font: {
                weight: 700;
                family: $font-family;
            }
            span {
                text-transform: capitalize;
                margin: 0;
                opacity: 1;
                display: inline;
                letter-spacing: 0;
                font: {
                    size: 55px;
                }
            }
        }
    }
    p {
        color: $white-color;
        margin-bottom: 30px;
        max-width: 580px;
        opacity: 0.88;
        font-size: 16.5px;
    }
    .btn-box {
        span {
            display: inline-block;
            letter-spacing: 0;
            margin: 0 15px;
            font: {
                weight: 700;
                size: 14px;
            }
        }
        .video-btn {
            color: #dddddd;
            display: inline-block;
            font-weight: 600;

            i {
                width: 50px;
                display: inline-block;
                height: 50px;
                line-height: 51px;
                text-align: center;
                background-color: $white-color;
                border-radius: 50%;
                margin-right: 5px;
                z-index: 1;
                color: $main-color;
                position: relative;
                transition: $transition;

                &::after {
                    z-index: -1;
                    content: "";
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 50px;
                    height: 50px;
                    animation: ripple 1.6s ease-out infinite;
                    opacity: 0;
                    border-radius: 50%;
                    background: rgba(255, 255, 255, 0.5);
                }
            }
            &:hover,
            &:focus {
                color: $white-color;

                i {
                    background-color: $main-color;
                    color: $white-color;
                }
            }
        }
    }
}
.main-banner {
    &.main-banner-with-form {
        height: 840px;

        .main-banner-content {
            margin-top: 0;
            max-width: 100%;
        }
    }
}
.banner-form {
    background: $white-color;
    box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
    padding: 40px 25px;
    position: relative;

    &::before {
        content: "";
        position: absolute;
        z-index: -1;
        background: $white-color;
        width: 96%;
        opacity: 0.62;
        height: 50%;
        bottom: -10px;
        left: 0;
        right: 0;
        margin: auto;
        border-radius: 3px;
    }
    form {
        .form-control {
            border: 1px solid #eeeeee;
            font-size: 14px;
            border-radius: 2px;
        }
        label {
            color: $paragraph-color;
            margin-bottom: 8px;
            font-size: 15px;
        }
        select.form-control {
            height: 50px;
            cursor: pointer;
        }
        .btn {
            margin-top: 10px;
        }
    }
}
.banner-video {
    position: relative;
    text-align: center;
    border: 5px solid $white-color;
    box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.05);
    overflow: hidden;

    &::before {
        background: linear-gradient(
            to bottom,
            #0975bc,
            #0d5b93,
            #0d426d,
            #092b48,
            #041527
        );
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        position: absolute;
        content: "";
        opacity: 0.7;
        z-index: 1;
    }
    img {
        transition: $transition;
    }
    .video-btn {
        position: absolute;
        left: 0;
        top: 50%;
        z-index: 2;
        right: 0;
        margin: 0 auto;
        width: 70px;
        height: 70px;
        line-height: 72px;
        border-radius: 50%;
        margin-top: -30px;
        color: $white-color;
        font-size: 20px;
        background-color: $main-color;
        transition: $transition;

        &::before {
            z-index: -1;
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            width: 70px;
            height: 70px;
            animation: ripple 1.6s ease-out infinite;
            opacity: 0;
            border-radius: 50%;
            background: rgba(148, 107, 255, 0.5);
        }
        &:hover,
        &:focus {
            color: $main-color;
            background-color: $white-color;
        }
    }
    &:hover,
    &:focus {
        img {
            transform: scale(1.4);
        }
    }
}
.text-animation-home {
    .main-banner-content {
        h1 {
            margin-bottom: 15px;
        }
    }
}
#particles-js {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
}
#particles-js-bubble {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
}
#particles-js-star {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
}
#particles-js-big-bubble {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
}
#particles-js-small-bubble {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
}

.home-slides {
    &.owl-theme {
        .owl-nav {
            margin-top: 0;
            line-height: 0.01;

            [class*="owl-"] {
                position: absolute;
                left: 50px;
                top: 50%;
                width: 60px;
                height: 60px;
                outline: 0;
                box-shadow: unset;
                opacity: 0;
                visibility: hidden;
                line-height: 60px;
                background-color: $black-color;
                border-radius: 0;
                color: $white-color;
                transition: $transition;
                opacity: 0.7;
                margin-top: -50px;
                font: {
                    size: 28px;
                }
                &.owl-next {
                    left: auto;
                    right: 50px;
                }
                &:hover {
                    background-color: $main-color;
                    color: $white-color;
                    opacity: 1;
                    outline: 0;
                    box-shadow: unset;
                }
            }
        }
        &:hover,
        &:focus {
            .owl-nav {
                [class*="owl-"] {
                    opacity: 1;
                    left: 10px;
                    visibility: visible;

                    &.owl-next {
                        left: auto;
                        right: 10px;
                    }
                }
            }
        }
    }
}
@keyframes ripple {
    0%,
    35% {
        transform: scale(0);
        opacity: 1;
    }
    50% {
        transform: scale(1.5);
        opacity: 0.8;
    }
    100% {
        opacity: 0;
        transform: scale(2);
    }
}
@keyframes imageAnimation {
    0% {
        opacity: 0;
        animation-timing-function: ease-in;
    }
    12.5% {
        opacity: 1;
        animation-timing-function: ease-out;
    }
    25% {
        opacity: 1;
    }
    37.5% {
        opacity: 0;
    }
    100% {
        opacity: 0;
    }
}
.no-cssanimations {
    .slideshow {
        li {
            span {
                opacity: 1;
            }
        }
    }
}
.shape1 {
    position: absolute;
    left: 70px;
    bottom: 70px;
    z-index: -1;
    animation: movebounce 5s linear infinite;
    opacity: 0.6;
}
.shape2 {
    position: absolute;
    right: 70px;
    bottom: 70px;
    z-index: -1;
    opacity: 0.6;
}
.shape3 {
    position: absolute;
    left: 70px;
    top: 70px;
    z-index: -1;
    opacity: 0.6;
}
.shape4 {
    position: absolute;
    right: 100px;
    top: 100px;
    z-index: -1;
    opacity: 0.6;
    animation: movebounce 5s linear infinite;
}
.rotateme {
    animation-name: rotateme;
    animation-duration: 40s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}
@keyframes rotateme {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
@keyframes movebounce {
    0% {
        transform: translateY(0px);
    }
    50% {
        transform: translateY(20px);
    }
    100% {
        transform: translateY(0px);
    }
}

/*================================================
Features CSS
=================================================*/
.features-area {
    position: relative;
    padding-bottom: 70px;
    z-index: 1;

    &.bg-image {
        background: {
            image: url(assets/img/bg.jpg);
            position: center center;
            size: cover;
            repeat: no-repeat;
        }
    }
}
.single-features {
    background-color: $white-color;
    box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
    transition: $transition;
    padding: 35px 25px;
    text-align: center;
    margin-bottom: 30px;
    transition: $transition;

    .icon {
        transition: $transition;
        color: $main-color;
        font: {
            size: 45px;
        }
    }
    h3 {
        transition: $transition;
        font: {
            size: 22px;
            weight: 600;
        }
        margin: {
            top: 15px;
            bottom: 12px;
        }
    }
    p {
        transition: $transition;
    }
    &:hover,
    &:focus {
        background-color: $main-color;
        transform: translateY(-10px);

        .icon {
            color: $white-color;
        }
        h3 {
            color: $white-color;
        }
        p {
            color: $white-color;
            opacity: 0.88;
        }
    }
    &.active {
        background-color: $main-color;

        .icon {
            color: $white-color;
        }
        h3 {
            color: $white-color;
        }
        p {
            color: $white-color;
            opacity: 0.88;
        }
    }
}

/*================================================
About CSS
=================================================*/
.about-area {
    position: relative;
    z-index: 1;

    &.bg-image {
        background: {
            image: url(assets/img/bg2.jpg);
            position: center center;
            size: contain;
            repeat: no-repeat;
        }
    }
    &.bg-image2 {
        background: {
            image: url(assets/img/bg.jpg);
            position: center center;
            size: cover;
            repeat: no-repeat;
        }
    }
}
.about-image {
    .col-lg-6 {
        &:first-child {
            .image {
                img {
                    border-radius: 25px 0 0 0;
                }
            }
        }
        &:nth-child(2) {
            .image {
                img {
                    border-radius: 0 25px 0 0;
                }
            }
        }
        &:nth-child(3) {
            .image {
                img {
                    border-radius: 0 0 0 25px;
                }
            }
        }
        &:last-child {
            .image {
                img {
                    border-radius: 0 0 25px 0;
                }
            }
        }
    }
    .image {
        img {
            box-shadow: 0px 10px 20px 0px rgba(130, 136, 147, 0.15);
            transition: $transition;

            &:hover,
            &:focus {
                transform: translateY(-10px);
            }
        }
    }
}
.about-content {
    padding-left: 10px;

    span {
        text-transform: uppercase;
        display: block;
        letter-spacing: 0.5px;
        margin-bottom: 10px;
        color: $main-color;
        font: {
            size: 14px;
            weight: 700;
        }
    }
    h2 {
        margin-bottom: 15px;
        font: {
            size: 33px;
            weight: 500;
        }
        b {
            font-weight: 600 !important;
            background: linear-gradient(
                to bottom,
                rgba(0, 0, 0, 0) 80%,
                $main-color 80%
            );
            background-size: 90px 1.1em !important;
        }
    }
    ul {
        padding: 0;
        margin: 15px 0 0;
        list-style-type: none;

        li {
            color: $paragraph-color;
            margin-bottom: 10px;
            font-weight: 600;
            padding-left: 17px;
            position: relative;

            &:last-child {
                margin-bottom: 0;
            }
            &::before {
                content: "";
                top: 6px;
                left: 0;
                position: absolute;
                width: 10px;
                height: 10px;
                background: $main-color;
                clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
            }
        }
    }
    p {
        margin: {
            bottom: 0;
            top: 15px;
        }
    }
}

/*================================================
CTA CSS
=================================================*/
.cta-area {
    position: relative;
    z-index: 1;
    background: {
        position: center center;
        size: cover;
        attachment: fixed;
        repeat: no-repeat;
        image: url(assets/img/cta-bg.jpg);
    }
    padding: {
        top: 70px;
        bottom: 70px;
    }
    &::before {
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: $main-color;
        position: absolute;
        content: "";
        z-index: -1;
        opacity: 0.85;
    }
    .cta-content {
        h3 {
            text-transform: capitalize;
            margin-bottom: 0;
            color: $white-color;
            font: {
                size: 34px;
                weight: 500;
            }
            b {
                font-weight: 600 !important;
                background: linear-gradient(
                    to bottom,
                    rgba(0, 0, 0, 0) 80%,
                    $black-color 80%
                );
                background-size: 90px 1.1em !important;
            }
        }
        span {
            text-transform: capitalize;
            display: block;
            color: $white-color;
            margin-top: 12px;
            opacity: 0.88;
            font: {
                size: 14px;
                weight: 600;
            }
        }
    }
    .btn {
        color: $black-color;

        &:hover,
        &:focus {
            color: $white-color;
        }
        &::before,
        &::after {
            background: $white-color;
        }
    }
}

/*================================================
Services CSS
=================================================*/
.services-area {
    position: relative;
    padding-bottom: 70px;
    z-index: 1;
}
.single-services {
    margin-bottom: 30px;
    transition: $transition;
    background: $white-color;
    box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);

    .services-image {
        overflow: hidden;
        position: relative;

        &::before {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background: $black-color;
            z-index: 2;
            opacity: 0;
            visibility: hidden;
            transition: $transition;
        }
        img {
            transition: $transition;
        }
        .icon {
            position: absolute;
            left: 0;
            top: 50%;
            z-index: 3;
            right: 0;
            margin: 0 auto;
            text-align: center;
            transform: translateY(-40%);
            width: 60px;
            opacity: 0;
            visibility: hidden;
            height: 60px;
            line-height: 58px;
            background: $main-color;
            color: $white-color;
            border-radius: 50%;
            font-size: 22px;
            transition: $transition;
        }
    }
    .services-content {
        padding: 30px 20px;

        h3 {
            transition: $transition;
            font: {
                size: 22px;
                weight: 600;
            }
            margin: {
                bottom: 12px;
            }
        }
        p {
            transition: $transition;
        }
    }
    &:hover,
    &:focus {
        background: $main-color;

        .services-image {
            img {
                transform: scale(1.4);
            }
            &::before {
                opacity: 0.7;
                visibility: visible;
            }
            .icon {
                transform: translateY(-50%);
                opacity: 1;
                visibility: visible;
            }
        }
        .services-content {
            h3,
            p {
                color: $white-color;
            }
        }
    }
}

/*================================================
Who We Are CSS
=================================================*/
.who-we-are {
    position: relative;
    padding-bottom: 70px;
    z-index: 1;

    &.bg-image {
        background: {
            image: url(assets/img/bg.jpg);
            position: center center;
            size: cover;
            repeat: no-repeat;
        }
    }
    .section-title {
        text-align: left;
        margin-bottom: 0;

        p {
            margin: {
                bottom: 25px;
                left: 0;
                right: 0;
            }
        }
    }
    .content {
        p {
            margin-bottom: 0;
        }
        ul {
            padding: 0;
            list-style-type: none;
            margin: 10px 0 0;
            overflow: hidden;

            li {
                color: $paragraph-color;
                margin-top: 10px;
                float: left;
                width: 45%;
                padding-left: 17px;
                font-weight: 600;
                position: relative;

                &::before {
                    content: "";
                    top: 50%;
                    transform: translateY(-50%);
                    left: 0;
                    position: absolute;
                    width: 10px;
                    height: 10px;
                    background: $main-color;
                    clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
                }
            }
        }
    }
}

/*================================================
Skill CSS
=================================================*/
.skill-image {
    width: 100%;
    height: 100%;
    background: {
        image: url(assets/img/skill-bg.jpg);
        position: center center;
        size: cover;
        repeat: no-repeat;
    }
    img {
        display: none;
    }
}
.skill-content {
    background-color: $main-color;
    padding: {
        left: 100px;
        right: 100px;
    }
    .section-title {
        text-align: left;
        margin-bottom: 35px;

        span {
            color: $white-color;
            opacity: 0.95;
        }
        h2 {
            color: $white-color;

            b {
                background: linear-gradient(
                    to bottom,
                    rgba(0, 0, 0, 0) 80%,
                    $black-color 80%
                );
            }
        }
        p {
            color: $white-color;
            opacity: 0.95;
            margin: {
                left: 0;
                right: 0;
            }
        }
    }
    .skills {
        position: relative;

        .skill-item {
            position: relative;
            margin-bottom: 25px;

            .skill-header {
                position: relative;
                margin-bottom: 10px;

                .skill-title {
                    margin-bottom: 0;
                    color: $white-color;
                    font: {
                        size: 16px;
                        weight: 600;
                    }
                }
                .skill-percentage {
                    position: absolute;
                    right: 0;
                    top: 1px;
                    color: $white-color;
                    font: {
                        size: 15px;
                        weight: 600;
                    }
                }
            }
            &:last-child {
                margin-bottom: 0;
            }
            .skill-bar {
                position: relative;
                width: 100%;

                .bar-inner {
                    position: relative;
                    width: 100%;
                    border-radius: 30px;
                    border: {
                        top: 3px solid $black-color;
                        bottom: 3px solid $black-color;
                    }
                    .bar {
                        position: absolute;
                        left: 0;
                        top: 0;
                        width: 0;
                        height: 6px;
                        background: $white-color;
                        transition: all 2000ms ease;
                        border-radius: 30px;
                        margin-top: -3px;
                    }
                }
            }
        }
    }
}

/*================================================
Why We Different CSS
=================================================*/
.why-we-different {
    position: relative;
    z-index: 1;

    &.bg-image {
        background: {
            image: url(assets/img/bg.jpg);
            position: center center;
            size: cover;
            repeat: no-repeat;
        }
    }
}
.tab {
    .tabs {
        list-style-type: none;
        padding: 0;
        margin: 0 0 50px;
        text-align: center;

        li {
            display: inline-block;
            margin: 0 5px;

            a {
                color: $black-color;
                padding: 15px 30px;
                background: transparent;
                transition: $transition;
                display: block;
                border: 1px solid #eeeeee;
                border-radius: 5px;
                text-transform: capitalize;
                font-weight: 600;

                &:hover,
                &:focus {
                    background: $main-color;
                    color: $white-color;
                    border-color: $main-color;
                }
            }
            &.current {
                a {
                    background: $main-color;
                    color: $white-color;
                    border-color: $main-color;
                }
            }
        }
    }
    .tabs_item {
        display: none;

        &:first-child {
            display: block;
        }
        .tabs_item_content {
            h3 {
                margin-bottom: 12px;
                font: {
                    size: 22px;
                    weight: 600;
                }
            }
            p {
                margin-bottom: 0;
            }
            ul {
                padding: 0;
                list-style-type: none;
                margin: 20px 0;
                overflow: hidden;

                li {
                    color: $paragraph-color;
                    margin-bottom: 10px;
                    float: left;
                    width: 45%;
                    padding-left: 17px;
                    font-weight: 600;
                    position: relative;

                    &:last-child {
                        margin-bottom: 0;
                    }
                    &::before {
                        content: "";
                        top: 50%;
                        transform: translateY(-50%);
                        left: 0;
                        position: absolute;
                        width: 10px;
                        height: 10px;
                        background: $main-color;
                        clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
                    }
                }
            }
        }
    }
}

/*================================================
Portfolio CSS
=================================================*/
.portfolio-area {
    position: relative;
    padding-bottom: 70px;
    z-index: 1;

    &.bg-image {
        background: {
            image: url(assets/img/bg.jpg);
            position: center center;
            size: cover;
            repeat: no-repeat;
        }
    }
}
.shorting-menu {
    text-align: center;
    margin-bottom: 40px;

    .filter {
        border: none;
        margin: 0 15px;
        position: relative;
        font-weight: 600;
        outline: 0 !important;
        box-shadow: unset !important;
        background: transparent;
        transition: $transition;
        z-index: 1;

        &::before {
            clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
            background: #bbbbbb;
            content: "";
            position: absolute;
            left: -22px;
            top: 50%;
            width: 10px;
            margin-top: -4px;
            height: 10px;
        }
        &::after {
            position: absolute;
            content: "";
            width: 12px;
            height: 2px;
            z-index: -1;
            background: $black-color;
            left: 3px;
            opacity: 0;
            visibility: hidden;
            transition: $transition;
            bottom: 8px;
            transform: rotate(0);
        }
        &:first-child {
            &::before {
                display: none;
            }
        }
        &:hover,
        &:focus,
        &.active {
            color: $main-color;

            &::after {
                opacity: 1;
                transform: rotate(45deg);
                visibility: visible;
            }
        }
    }
}
.shorting {
    .mix {
        display: none;
    }
}
.single-work {
    margin-bottom: 30px;
    overflow: hidden;
    position: relative;

    a {
        &.popup-btn {
            position: absolute;
            width: 100%;
            display: block;
            height: 100%;
            z-index: 1;
        }
    }
    .work-image {
        position: relative;

        img {
            transition: $transition;
        }
        .work-overlay {
            position: absolute;
            overflow: hidden;
            top: 10px;
            left: 10px;
            right: 10px;
            bottom: 10px;

            &::before {
                content: "";
                transition: $transition;
                position: absolute;
                width: 100%;
                bottom: 0;
                height: 50%;
                background: $main-color;
                right: -100%;
            }
            &::after {
                content: "";
                transition: $transition;
                position: absolute;
                background: $main-color;
                top: 0;
                height: 50%;
                width: 100%;
                left: -100%;
            }
            h3 {
                color: transparent;
                overflow: hidden;
                position: absolute;
                top: 30px;
                text-align: center;
                right: 25px;
                margin: 0;
                transition: $transition;
                z-index: 2;
                font: {
                    size: 22px;
                    weight: 600;
                }
                &::before {
                    content: "";
                    background: $black-color;
                    position: absolute;
                    width: 100%;
                    bottom: 0;
                    transition: $transition;
                    left: 100%;
                    top: 0;
                    right: auto;
                }
            }
            span {
                color: transparent;
                overflow: hidden;
                z-index: 2;
                position: absolute;
                top: 62px;
                display: block;
                right: 25px;
                transition: $transition;
                text-align: center;
                font: {
                    weight: 600;
                }
                &::before {
                    content: "";
                    background: $white-color;
                    width: 100%;
                    top: 0;
                    bottom: 0;
                    position: absolute;
                    transition: $transition;
                    right: 100%;
                }
            }
        }
    }
    &:hover,
    &:focus {
        .work-image {
            img {
                transform: scale(1.3);
            }
            .work-overlay {
                &::before {
                    right: 0;
                    opacity: 1;
                    width: 100%;
                    height: 50%;
                    transition: all 0.8s;
                }
                &::after {
                    left: 0;
                    opacity: 1;
                    transition: all 0.8s;
                }
                span {
                    color: $white-color;

                    &::before {
                        right: -100%;
                    }
                }
                h3 {
                    color: $white-color;

                    &::before {
                        left: -100%;
                    }
                }
            }
        }
    }
}

/*================================================
Fun Facts CSS
=================================================*/
.funfacts-area {
    position: relative;
    z-index: 1;

    &.bg-image {
        background: {
            image: url(assets/img/overlay.png);
            position: center center;
            size: cover;
            repeat: no-repeat;
        }
    }
    .section-title {
        margin-bottom: 0;
        text-align: left;

        p {
            margin: {
                bottom: 25px;
                left: 0;
                right: 0;
            }
        }
    }
}
.counter-wrap {
    .single-counter {
        padding-left: 125px;
        position: relative;
        margin: {
            left: 40px;
            bottom: 30px;
        }
        h2 {
            position: absolute;
            left: 0;
            top: 0;
            color: $main-color;
            margin-bottom: 0;
            font: {
                size: 45px;
                weight: 600;
            }
        }
        h3 {
            margin-bottom: 12px;
            font: {
                size: 22px;
                weight: 600;
            }
        }
        p {
            margin-bottom: 0;
        }
        &:last-child {
            margin-bottom: 0;
        }
    }
    .odometer-formatting-mark {
        display: none;
    }
}
/*funfacts-style-two*/
.funfacts-area-two {
    position: relative;
    z-index: 1;
    background: {
        position: center center;
        size: cover;
        attachment: fixed;
        repeat: no-repeat;
        image: url(assets/img/funfacts-bg.jpg);
    }
    &::before {
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: $black-color;
        position: absolute;
        content: "";
        opacity: 0.7;
    }
}
.single-funfact {
    text-align: center;

    i {
        color: $main-color;
        font-size: 40px;
    }
    .odometer-formatting-mark {
        display: none;
    }
    h3 {
        margin: 25px 0 6px;
        display: block !important;
        color: $white-color;
        font: {
            size: 35px;
            weight: 700;
        }
    }
    p {
        color: $white-color;
        opacity: 0.88;
    }
}

/*================================================
Team CSS
=================================================*/
.team-area {
    position: relative;
    z-index: 1;

    &.bg-image {
        background: {
            image: url(assets/img/bg.jpg);
            position: center center;
            size: cover;
            repeat: no-repeat;
        }
    }
}
.team-box {
    overflow: hidden;
    position: relative;

    &::before {
        content: "";
        display: block;
        border: 10px solid rgba(255, 255, 255, 0.3);
        position: absolute;
        top: 5px;
        left: 5px;
        bottom: 5px;
        right: 5px;
        opacity: 1;
        z-index: 2;
        transition: all 1s ease 0s;
    }
    &::after {
        content: "";
        display: block;
        border: 5px solid rgba(255, 255, 255, 0.7);
        position: absolute;
        top: 15px;
        left: 15px;
        bottom: 15px;
        right: 15px;
        opacity: 1;
        z-index: 1;
        transition: all 1s ease 0s;
    }
    img {
        width: auto !important;
        transform: scale(1);
        transition: all 1s ease 0s;
        display: inline-block !important;
    }
    .box-content {
        padding: 20px;
        text-align: center;
        position: absolute;
        top: 45px;
        left: 45px;
        bottom: 45px;
        right: 45px;
        opacity: 1;
        z-index: 2;
        transition: all 0.3s ease 0s;

        .box-inner-content {
            width: 100%;
            padding: 20px;
            opacity: 0;
            background-color: $white-color;
            position: absolute;
            bottom: 0;
            left: 0;
            transition: $transition;

            .title {
                margin-bottom: 0;
                font: {
                    size: 22px;
                    weight: 600;
                }
            }
            .post {
                display: block;
                margin-top: 6px;
                color: $main-color;
            }
            ul {
                padding: 0;
                list-style-type: none;
                margin: 15px 0 0;

                li {
                    display: inline-block;
                    margin: 0 3px;

                    a {
                        width: 33px;
                        height: 33px;
                        line-height: 30px;
                        border: 1px solid #eeeeee;
                        border-radius: 50%;
                        color: $paragraph-color;
                        display: block;
                        font-size: 14px;

                        &:hover,
                        &:focus {
                            background-color: $main-color;
                            color: $white-color;
                            border-color: $main-color;
                        }
                    }
                }
            }
        }
    }
    &:hover,
    &:focus {
        &::before {
            border: 1px solid rgba(255, 255, 255, 0.18);
        }
        &::after {
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            opacity: 0;
        }
        img {
            transform: scale(1.2);
        }
        .box-content {
            top: 6px;
            left: 6px;
            bottom: 6px;
            right: 6px;

            .box-inner-content {
                opacity: 1;
            }
        }
    }
}
.team-slides {
    &.owl-theme {
        .owl-nav {
            margin-top: 0;
            line-height: 0.01;

            [class*="owl-"] {
                position: absolute;
                left: 0;
                top: 50%;
                transform: translateY(-50%);
                outline: 0;
                margin: 0;
                font-size: 20px;
                box-shadow: unset;
                border-radius: 0;
                transition: $transition;
                width: 40px;
                opacity: 0;
                visibility: hidden;
                height: 40px;
                background-color: $paragraph-color;
                line-height: 40px;
                color: $white-color;

                &.owl-next {
                    left: auto;
                    right: 0;
                }
                &:hover {
                    background-color: $main-color;
                    color: $white-color;
                }
            }
        }
        &:hover,
        &:focus {
            .owl-nav {
                [class*="owl-"] {
                    left: -40px;
                    opacity: 1;
                    visibility: visible;

                    &.owl-next {
                        left: auto;
                        right: -40px;
                    }
                }
            }
        }
    }
}

/*================================================
Story CSS
=================================================*/
.story-area {
    position: relative;
    z-index: 1;
    background: {
        position: center center;
        size: cover;
        repeat: no-repeat;
        image: url(assets/img/story-bg.jpg);
    }
    &::before {
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: $black-color;
        position: absolute;
        content: "";
        opacity: 0.7;
    }
}
.story-content {
    max-width: 520px;
    margin: 0 auto;

    .section-title {
        margin-bottom: 0;

        h2 {
            color: $white-color;
            line-height: 48px;
            margin-bottom: 35px;
        }
    }
    .video-btn {
        display: inline-block;

        i {
            width: 70px;
            display: inline-block;
            height: 70px;
            line-height: 72px;
            text-align: center;
            background-color: $white-color;
            border-radius: 50%;
            z-index: 1;
            color: $main-color;
            font-size: 25px;
            position: relative;
            transition: $transition;

            &::after {
                z-index: -1;
                content: "";
                position: absolute;
                left: 0;
                top: 0;
                width: 70px;
                height: 70px;
                animation: ripple 1.6s ease-out infinite;
                opacity: 0;
                border-radius: 50%;
                background: rgba(255, 255, 255, 0.5);
            }
        }
        &:hover,
        &:focus {
            i {
                background-color: $main-color;
                color: $white-color;
            }
        }
    }
}

/*================================================
Pricing CSS
=================================================*/
.tab-slider-nav {
    text-align: center;
    margin-bottom: 40px;

    .tab-slider-tabs {
        margin: 0;
        padding: 0;
        list-style: none;

        .tab-slider-trigger {
            color: $paragraph-color;
            text-transform: uppercase;
            background-color: $white-color;
            margin: 0 -2px;
            padding: 10px 20px;
            box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
            cursor: pointer;
            border-bottom: 3px solid $main-color;
            display: inline-block;
            transition: $transition;
            font: {
                weight: 600;
                size: 14px;
            }
            &.active,
            &:hover,
            &:focus {
                color: $white-color;
                background: $main-color;
            }
        }
    }
}

.section-title {
    h2 {
        color: #64677b;
        margin-top: 4.5rem;
        margin-bottom: 3.4rem;
        font-weight: 400;
    }
}
.selected-product {
    padding: 5rem 0;
    h3 {
        font-weight: bold;
        color: $paragraph-color;
        span {
            font-weight: normal;
            padding-left: 1rem;
        }
    }
}
.pricing-table {
    text-align: center;
    padding-bottom: 35px;
    //  box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
    //transition: $transition;
    background-color: $white-color;
    border: 1px solid #cccccc;
    border-radius: 2rem;
    position: relative;

    .pricing-header {
        position: absolute !important;
        top: -31px;
        left: 0;
        padding: 6px 5px;
        border: 1px solid #f4f4f4;
        //border-left:1px solid;
        //border-image: linear-gradient(to top, #cccccc 25%, #f4f4f4 75%) 1;
        transition: 0.5s;
        background: #f4f4f4;
        width: 80%;
        margin: auto 10%;
        position: relative;
        z-index: 1;
        font-size: 22px;
        font-weight: 700;
        border-radius: 12.5rem;
        h5 {
            padding: 10px 20px;
            border: 1px solid #cccccc;
            border-radius: 2rem;
            background: #fff;
            font-weight: bold;
            color: #c12228;
            font-size: 1.2rem;
            white-space: nowrap;
        }
    }

    .prdct-con {
        text-align: center;
        width: 100%;
        // height:150px;
        // padding-top: 3rem;
        // padding-top: 3rem;
        i {
            font-size: 7rem;
            color: $red-color;
        }
    }
    .price {
        color: $red-color;
        font: {
            size: 40px;
            weight: 700;
        }
        span {
            color: $main-color;
            font-size: 1.2rem;
        }
        margin: {
            top: 15px;
            bottom: 15px;
        }
    }
    .singleClass {
        background-color: $red-color !important;
        border-color: $red-color !important;
    }
    a.slct-chk {
        position: absolute;
        z-index: inherit;
        bottom: -35px;
        left: 35%;
        background-color: #c9c9c9;
        border: 1px solid #c9c9c9;
        width: 5rem;
        height: 5rem;
        border-radius: 25rem;
        i {
            color: $white-color;
            font-size: 3rem;
            line-height: 5rem;
            font-weight: normal;
        }
    }
    .pricing-features {
        padding: 5px 15px 5px 10px;
        margin: 5px 0px;
        list-style-type: none;
        font-size: 0.9rem;

        li {
            color: $main-color;
            margin-bottom: 13px;
            text-align: left;

            &:last-child {
                margin-bottom: 0;
            }
            i {
                margin-right: 0.5rem;
            }
        }
    }

    &:hover {
        background-color: $main-color;
        h5 {
            background-color: $main-color;
            color: #fff;
        }
        a.slct-chk {
            background-color: $red-color;
            border-color: $red-color;
        }
        .price {
            color: $white-color;
            span {
                color: $white-color;
            }
        }
        .pricing-features {
            li {
                color: $white-color;
            }
        }
        // .prdct-con{
        //     filter: grayscale(100%);
        //     i{
        //     color:$white-color;
        //     }
        // }
    }
}

/*================================================
Testimonial CSS
=================================================*/
.testimonial-area {
    position: relative;
    z-index: 1;

    // &.bg-image {
    //     background: {
    //         image: url(assets/img/bg.jpg);
    //         position: center center;
    //         size: cover;
    //         repeat: no-repeat;
    //     };
    // }
}

.owl-stage-outer {
    background: {
        image: url(assets/img/testimonials_bg.png);
        position: right top;
        //size: cover;
        repeat: no-repeat;
        border: 1px solid #f00;
    }
}
.testimonial-item {
    padding: 30px 25% 30px 125px;
    margin-bottom: 30px;
    overflow: hidden;
    position: relative;
    z-index: 1;
    text-align: left;

    .client-image {
        display: inline-block;
        width: 80px;
        height: 80px;
        border-radius: 50%;
        overflow: hidden;
        position: absolute;
        top: 30px;
        left: 20px;

        img {
            border-radius: 50%;
            width: auto !important;
            display: inline-block !important;
        }
    }
    .testimonial-content {
        .client-info {
            margin-top: 20px;

            h3 {
                margin-bottom: 0;
                b {
                    color: #000 !important;
                }
                font: {
                    size: 18px;
                    weight: 600;
                }
            }
            span {
                display: block;
                color: $grey-color !important;
                text-transform: uppercase;
                letter-spacing: 3px;
            }
        }
    }
}
.testimonial-slides {
    &.owl-theme {
        .owl-nav {
            &.disabled {
                + .owl-dots {
                    margin-top: 25px;
                    line-height: 0.01;
                }
            }
        }
        .owl-dots {
            .owl-dot {
                margin-right: 1rem;
                border: 1px solid #f7f7f7;
                border-radius: 50%;
                span {
                    width: 5px;
                    height: 5px;
                    margin: 0 4px;
                    display: block;
                    transition: $transition;
                    border-radius: 50%;
                    position: relative;
                    margin: 0.4rem;
                }
                &:hover {
                    border: 1px solid #f7f7f7;
                    border-radius: 50%;
                    span {
                        background: $main-color;
                        border-color: $main-color;
                        margin: 0.4rem;
                    }
                }
                &.active {
                    border: 1px solid #dfdfdf;
                    border-radius: 50%;
                    span {
                        background: $main-color;
                    }
                }
            }
        }
    }
}

/*================================================
Call To Action CSS
=================================================*/
.call-to-action {
    position: relative;
    z-index: 1;
    background: {
        position: center center;
        size: cover;
        attachment: fixed;
        repeat: no-repeat;
        image: url(assets/img/call-to-action-bg.jpg);
    }
    &::before {
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: $black-color;
        position: absolute;
        content: "";
        opacity: 0.7;
    }
}
.call-to-action-content {
    max-width: 520px;
    margin: 0 auto;

    .section-title {
        margin-bottom: 0;

        h2 {
            color: $white-color;
        }
    }
    p {
        color: $white-color;
    }
    .btn {
        background-color: $white-color;
        margin-top: 25px;

        &:hover,
        &:focus,
        &.active {
            background-color: $white-color;
            color: $black-color;
        }
    }
}

/*================================================
FAQ CSS
=================================================*/
.faq-image {
    width: 100%;
    height: 100%;
    background: {
        image: url(assets/img/faq-bg.jpg);
        position: center center;
        size: cover;
        repeat: no-repeat;
    }
    img {
        display: none;
    }
}
.faq-accordion {
    .accordion-item {
        border-radius: 0 !important;
        display: block;
        margin-bottom: 15px;
        border: none;
        background-color: $white-color;
        box-shadow: 2px 8px 20px 0 rgba(25, 42, 70, 0.13) !important;

        .accordion-header {
            padding: 0;
            margin-bottom: 0;
            border: none;
            background: transparent;

            &:first-child {
                border-radius: 0;
            }
            .accordion-button {
                display: block;
                overflow: hidden;
                padding: 17px 40px 17px 20px;
                color: $white-color;
                box-shadow: unset;
                background: $main-color;
                position: relative;
                font: {
                    size: $font-size;
                    family: $font-family;
                    weight: 600;
                }
                &:hover {
                    background: $main-color;
                }
                i {
                    position: absolute;
                    right: 20px;
                    top: 50%;
                    font-size: 18px;
                    transform: translateY(-50%) rotate(180deg);
                }
                &.collapsed {
                    color: $black-color;
                    background: transparent;
                    transition: $transition;

                    &:hover {
                        color: $white-color;
                        background-color: $main-color;
                    }
                    i {
                        transform: translateY(-50%) rotate(0);
                    }
                }
                &::after {
                    display: none;
                }
            }
        }
        .accordion-body {
            line-height: 1.8;
            background: transparent;
            color: $paragraph-color;
            padding: 15px;
        }
        &:last-child {
            margin-bottom: 0;
        }
    }
}

/*================================================
Blog CSS
=================================================*/
.blog-area {
    position: relative;
    z-index: 1;

    &.bg-image {
        background: {
            image: url(assets/img/bg.jpg);
            position: center center;
            size: cover;
            repeat: no-repeat;
        }
    }
}
.single-blog-post {
    margin-bottom: 30px;
    background-color: $white-color;
    box-shadow: 0 15px 15px 0 rgba(0, 0, 0, 0.06);

    .blog-image {
        position: relative;
        overflow: hidden;

        a {
            display: block;

            img {
                transition: $transition;
            }
        }
        .post-tag {
            position: absolute;
            left: 0;
            top: 40px;
            transition: $transition;

            a {
                background: $main-color;
                color: $white-color;
                padding: 9px 28px;
                display: inline-block;
            }
        }
    }
    .blog-post-content {
        padding: 25px;

        .date {
            color: $main-color;
            display: block;
            text-transform: uppercase;
            transition: $transition;
            font: {
                size: 14px;
            }
        }
        h3 {
            line-height: 30px;
            margin: {
                top: 13px;
                bottom: 12px;
            }
            font: {
                size: 22px;
                weight: 600;
            }
            a {
                color: $black-color;
                display: inline-block;

                &:hover,
                &:focus {
                    color: $main-color;
                }
            }
        }
        p {
            margin-bottom: 18px;
        }
    }
    .read-more-btn {
        position: relative;
        font-weight: 500;
        color: $main-color;

        &:hover {
            color: $main-color;
            letter-spacing: 1px;
        }
    }
    &:hover,
    &:focus {
        .blog-image {
            a {
                img {
                    transform: scale(1.2);
                }
            }
            .post-tag {
                top: 50px;

                a {
                    background: $main-color;
                    color: $white-color;
                }
            }
        }
    }
}
.blog-slides {
    &.owl-theme {
        .owl-nav {
            &.disabled {
                + .owl-dots {
                    margin-top: 25px;
                    line-height: 0.01;
                }
            }
        }
        .owl-dots {
            .owl-dot {
                span {
                    width: 15px;
                    height: 15px;
                    margin: 0 4px;
                    background: transparent;
                    border: 1px solid #dfdfdf;
                    display: block;
                    transition: $transition;
                    border-radius: 50%;
                    position: relative;
                }
                &:hover,
                &:focus {
                    outline: 0;
                    box-shadow: unset !important;

                    span {
                        background: $main-color;
                        border-color: $main-color;
                    }
                }
                &.active {
                    span {
                        background: $main-color;
                        border-color: $main-color;
                    }
                }
            }
        }
    }
}

/*================================================
Partner CSS
=================================================*/
.partner-area {
    background-color: #f7f7f7;
}
.partner-item {
    background: $white-color;
    text-align: center;
    position: relative;
    z-index: 1;

    &::before,
    &::after {
        content: "";
        position: absolute;
        z-index: -1;
        width: 0;
        height: 0;
        opacity: 0;
        visibility: hidden;
        transition: $transition;
    }
    &::before {
        left: 0;
        top: 0;
        border: {
            left: 3px solid $main-color;
            top: 3px solid $main-color;
        }
    }
    &::after {
        right: 0;
        bottom: 0;
        border: {
            right: 3px solid $main-color;
            bottom: 3px solid $main-color;
        }
    }
    a {
        display: block;
        padding: 25px 15px;

        img {
            display: inline-block !important;
            width: auto !important;
            transition: $transition;
        }
    }
    &:hover,
    &:focus {
        &::after,
        &::before {
            width: 40px;
            height: 40px;
            opacity: 1;
            visibility: visible;
        }
        a {
            img {
                opacity: 0.66;
                transform: scale(0.9);
            }
        }
    }
}

/*================================================
Subscribe CSS
=================================================*/
.subscribe-area {
    background: {
        image: url(assets/img/pattern.png);
        position: center;
        size: contain;
        repeat: repeat;
    }
}
.newsletter {
    max-width: 750px;
    text-align: center;
    box-shadow: 0 0 30px 0 rgba(67, 67, 67, 0.15);
    background: $white-color;
    padding: 35px;
    border-radius: 5px;
    margin: {
        left: auto;
        right: auto;
    }
    h2 {
        text-transform: capitalize;
        margin-bottom: 30px;
        font: {
            size: 35px;
            weight: 500;
        }
        b {
            font-weight: 600 !important;
            background: linear-gradient(
                to bottom,
                rgba(0, 0, 0, 0) 80%,
                $main-color 80%
            );
            background-size: 90px 1.1em !important;
        }
    }
    form {
        position: relative;

        .form-control {
            box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
            border: none;
            height: 75px;
            border-radius: 50px;
            padding-left: 20px;
            color: $black-color;
            font: {
                weight: 500;
                size: 16px;
            }
            &::placeholder {
                color: $paragraph-color;
            }
        }
        button {
            position: absolute;
            right: 7px;
            border-radius: 30px;
            top: 7px;
            height: 60px;
            width: 175px;
            background: $main-color;
            border: none;
            outline: 0;
            cursor: pointer;
            transition: $transition;
            color: $white-color;
            font: {
                weight: 600;
                size: 16px;
            }
            &:hover,
            &:focus {
                background-color: $black-color;
                color: $white-color;
            }
        }
    }
}

/*================================================
Contact CSS
=================================================*/
.contact-area {
    position: relative;
    z-index: 1;

    &.bg-image {
        background: {
            image: url(assets/img/bg.jpg);
            position: center center;
            size: cover;
            repeat: no-repeat;
        }
    }
}
.contact-info {
    box-shadow: 0 0 30px 0 rgba(67, 67, 67, 0.15);
    background: $white-color;
    padding: 30px;
    height: 100%;
    border: {
        top: 3px solid $main-color;
        bottom: 3px solid $main-color;
    }
    ul {
        padding: 0;
        margin: 0;
        list-style-type: none;

        li {
            position: relative;
            padding-left: 60px;
            font-weight: 600;
            margin-bottom: 25px;
            color: $paragraph-color;

            &:last-child {
                margin-bottom: 0;
            }
            span {
                display: block;
                margin-bottom: 3px;
                color: $black-color;
                font: {
                    weight: 600;
                    size: 20px;
                }
            }
            a {
                color: $paragraph-color;
                display: inline-block;

                &:hover,
                &:focus {
                    color: $main-color;
                }
            }
            i {
                position: absolute;
                left: 0;
                top: 50%;
                color: $main-color;
                font-size: 19px;
                background: #f6f6f6;
                transform: translateY(-50%);
                width: 44px;
                height: 44px;
                line-height: 44px;
                text-align: center;
                border-radius: 50%;
                transition: $transition;
            }
            &:hover,
            &:focus {
                i {
                    background-color: $main-color;
                    color: $white-color;
                }
            }
        }
    }
}
.contact-form {
    padding: 30px;
  
    .btn {
        margin-top: 12px;
    }
    textarea.form-control {
        min-height: auto;
        height: auto;
    }
}

.contact-form {
    .form-control {
        border:  1px solid $main-color;
        
    }
    
}

/*================================================
Page Title CSS
=================================================*/
.page-title-area {
    position: relative;
    z-index: 1;
    text-align: center;
    background: {
        position: center center;
        size: cover;
        repeat: no-repeat;
    }
    padding: {
        top: 200px;
        bottom: 0px;
    }
    // &::before {
    //     position: absolute;
    //     left: 0;
    //     top: 0;
    //     background: rgba(13, 15, 20, 0.75);
    //     width: 100%;
    //     height: 100%;
    //     z-index: -1;
    //     content: '';
    // }
    h2 {
        margin-bottom: 0;
        color: $main-color;
        font: {
            size: 45px;
            weight: 600;
        }
    }
}
.page-title-area-narrow {
    position: relative;
    z-index: 1;
    text-align: center;
    background: {
        position: center center;
        size: cover;
        repeat: no-repeat;
    }
    padding: {
        top: 100px;
        bottom: 0px;
    }
    &::before {
        position: absolute;
        left: 0;
        top: 0;
        // background: rgba(13, 15, 20, 0.75);
        width: 100%;
        height: 50%;
        z-index: -1;
        content: "";
    }
    h2 {
        margin-bottom: 0;
        color: $black-color;
        font: {
            size: 25px;
            weight: 600;
        }
    }
}
/*================================================
Pagination CSS
=================================================*/
.pagination-area {
    margin-top: 30px;

    ul {
        padding: 0;
        margin: 0;
        list-style-type: none;

        li {
            a {
                width: 40px;
                height: 40px;
                padding: 0;
                margin: 0 5px;
                background-color: $white-color;
                border-radius: 0 !important;
                text-align: center;
                line-height: 40px;
                color: $black-color;
                box-shadow: 0 2px 10px 0 #d8dde6;
                border: none;
                font: {
                    size: 17px;
                    weight: 600;
                }
                &.active,
                &:hover,
                &:focus {
                    background: $main-color;
                    color: $white-color;
                    box-shadow: unset;
                }
            }
        }
    }
}

/*================================================
Sidebar CSS
=================================================*/
.sidebar {
    .widget {
        margin-bottom: 40px;

        &:last-child {
            margin-bottom: 0;
        }
        .widget-title {
            margin-bottom: 25px;
            position: relative;
            font: {
                size: 22px;
                weight: 600;
            }
            &::before {
                content: "";
                position: absolute;
                right: 0;
                top: 2px;
                width: 17px;
                height: 17px;
                background: $main-color;
                clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
                transition: $transition;
            }
        }
        &:hover,
        &:focus {
            .widget-title {
                &::before {
                    transform: rotate(180deg);
                }
            }
        }
        &.widget_search {
            background: $white-color;
            box-shadow: 0px 0px 29px 0px rgba(102, 102, 102, 0.1);
            padding: 15px;

            form {
                position: relative;

                .form-control {
                    background: transparent;
                }
                button {
                    position: absolute;
                    right: 5px;
                    top: 0;
                    height: 100%;
                    border: none;
                    outline: 0;
                    box-shadow: unset;
                    background: transparent;
                    color: $main-color;
                    transition: $transition;

                    &:hover,
                    &:focus {
                        color: $black-color;
                    }
                }
            }
        }
        &.widget_categories {
            ul {
                padding: 0;
                margin: 0;
                list-style-type: none;

                li {
                    position: relative;
                    border-top: 1px solid #eeeeee;
                    padding: {
                        top: 10px;
                        bottom: 10px;
                        left: 18px;
                    }
                    &::before {
                        content: "";
                        position: absolute;
                        left: 0;
                        top: 50%;
                        width: 10px;
                        height: 10px;
                        background: $main-color;
                        margin-top: -5px;
                        transition: $transition;
                    }
                    &:last-child {
                        border-bottom: 1px solid #eeeeee;
                    }
                    a {
                        color: $paragraph-color;
                        display: inline-block;

                        &:hover {
                            color: $main-color;
                        }
                    }
                    &:hover {
                        &::before {
                            border-radius: 50%;
                        }
                    }
                }
            }
        }
        &.widget_recent_entries {
            ul {
                padding: 0;
                margin: 0;
                list-style-type: none;

                li {
                    position: relative;
                    padding-left: 100px;
                    margin-bottom: 15px;

                    a {
                        display: block;

                        img {
                            position: absolute;
                            left: 0;
                            top: 3px;
                            height: 65px;
                        }
                    }
                    h5 {
                        line-height: 22px;
                        margin-bottom: 3px;
                        font: {
                            size: 16px;
                            weight: 600;
                        }
                        a {
                            color: $black-color;
                            display: inline-block;

                            &:hover,
                            &:focus {
                                color: $main-color;
                            }
                        }
                    }
                    p {
                        font-size: 14px;
                    }
                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
        }
        &.widget_tag_cloud {
            .tagcloud {
                a {
                    border: 1px dashed #eeeeee;
                    padding: 6px 20px;
                    margin-top: 6px;
                    color: $paragraph-color;
                    display: inline-block;
                    margin-right: 6px;
                    font: {
                        size: 14px !important;
                        weight: 600;
                    }
                    &:hover {
                        background: $main-color;
                        color: $white-color;
                        border-color: $main-color;
                    }
                }
            }
        }
        &.widget_archive {
            ul {
                padding: 0;
                margin: 0;
                list-style-type: none;

                li {
                    position: relative;
                    border-top: 1px solid #eeeeee;
                    padding: {
                        top: 10px;
                        bottom: 10px;
                        left: 18px;
                    }
                    &::before {
                        content: "";
                        position: absolute;
                        left: 0;
                        top: 50%;
                        width: 10px;
                        height: 10px;
                        background: $main-color;
                        margin-top: -5px;
                        transition: $transition;
                    }
                    &:last-child {
                        border-bottom: 1px solid #eeeeee;
                    }
                    a {
                        color: $paragraph-color;
                        display: inline-block;

                        &:hover {
                            color: $main-color;
                        }
                    }
                    &:hover {
                        &::before {
                            border-radius: 50%;
                        }
                    }
                }
            }
        }
    }
}

/*================================================
Blog Details CSS
=================================================*/
.blog-details {
    .post-image {
        margin-bottom: 35px;
    }
    h3 {
        font: {
            size: 22px;
            weight: 600;
        }
        margin: {
            top: 5px;
            bottom: 15px;
        }
    }
    .blog-meta {
        ul {
            padding: 0;
            margin: 20px 0;
            list-style-type: none;

            li {
                display: inline-block;
                position: relative;
                color: $paragraph-color;
                font: {
                    size: 14px;
                }
                margin: {
                    left: 12px;
                    right: 12px;
                }
                i {
                    margin-right: 5px;
                    color: $main-color;
                }
                &::before {
                    width: 1px;
                    height: 14px;
                    background: $paragraph-color;
                    left: -13px;
                    top: 50%;
                    position: absolute;
                    content: "";
                    margin-top: -6px;
                    transform: rotate(11deg);
                }
                a {
                    display: inline-block;
                    color: $paragraph-color;

                    &:hover,
                    &:focus {
                        color: $main-color;
                    }
                }
                &:last-child {
                    margin-right: 0;
                }
                &:first-child {
                    margin-left: 0;

                    &::before {
                        display: none;
                    }
                }
            }
        }
    }
    p {
        margin: {
            top: 15px;
            bottom: 0;
        }
        &:last-child {
            margin-bottom: 0;
        }
    }
    blockquote,
    .blockquote {
        background: #f6f6f6 none repeat scroll 0 0;
        padding: 35px 20px 35px 100px;
        position: relative;
        border-left: 5px solid $main-color;
        margin: {
            top: 25px;
            bottom: 25px;
        }
        &::before {
            font-family: IcoFont !important;
            position: absolute;
            content: "\efcd";
            left: 25px;
            top: 50%;
            transform: translateY(-50%);
            font-size: 55px;
            color: $main-color;
        }
        p {
            color: $black-color;
            margin: 0;
            font: {
                size: 18px;
                weight: 600;
            }
        }
    }
}
.post-tag-media {
    background-color: #f5f7fb;
    padding: 20px;
    margin: {
        top: 25px;
        bottom: 35px;
    }
    ul {
        padding: 0;
        margin: 0;
        list-style-type: none;

        li {
            display: inline-block;

            span {
                margin-right: 5px;
                display: inline-block;
                font: {
                    weight: 600;
                }
            }
            a {
                color: $paragraph-color;
                margin-right: 4px;
                display: inline-block;

                &:hover,
                &:focus {
                    color: $main-color;
                }
            }
        }
        &.social-share {
            text-align: right;

            li {
                span {
                    margin-right: 0;
                }
                a {
                    margin: {
                        right: 0;
                        left: 6px;
                    }
                }
            }
        }
    }
}
.comments-area {
    .comments-title,
    .comment-reply-title {
        margin-bottom: 25px;
        font: {
            size: 22px;
            weight: 600;
        }
    }
    .comment-reply-title {
        margin-bottom: 8px;
    }
    ol,
    ul {
        padding: 0;
        margin: 0;
        list-style-type: none;

        li {
            ol,
            ul {
                li {
                    margin: {
                        top: 15px;
                        left: 35px;
                        bottom: 15px;
                    }
                }
            }
            .comment-body {
                background: #f7f7ff;
                padding: 25px 20px;
                position: relative;

                .comment-meta {
                    position: relative;
                    padding: {
                        left: 85px;
                        top: 8px;
                    }
                    .comment-author {
                        img {
                            position: absolute;
                            left: 0;
                            top: 0;
                            border-radius: 50%;
                            max-width: 68px;
                        }
                        .fn {
                            margin-bottom: 5px;
                            display: block;
                            font: {
                                size: 16px;
                                weight: 600;
                            }
                        }
                        .says {
                            display: none;
                        }
                    }
                    .comment-metadata {
                        a {
                            display: inline-block;
                            color: $paragraph-color;
                            font-size: 14px;

                            &:hover,
                            &:focus {
                                color: $main-color;
                            }
                        }
                    }
                }
                .comment-content {
                    margin: {
                        top: 25px;
                    }
                }
                .reply {
                    position: absolute;
                    right: 30px;
                    top: 30px;

                    a {
                        display: inline-block;
                        background: $black-color;
                        padding: 5px 15px;
                        color: $white-color;

                        &:hover,
                        &:focus {
                            background: $main-color;
                        }
                    }
                }
            }
        }
    }
    .comment-respond {
        margin-top: 30px;

        .comment-notes {
            font-size: 14px;
            margin-bottom: 20px;
        }
        .comment-form-comment,
        .comment-form-author,
        .comment-form-email,
        .comment-form-url {
            input,
            textarea {
                display: block;
                width: 100%;
                height: 45px;
                outline: 0 !important;
                box-shadow: unset !important;
                border: 1px solid #eeeeee;
                padding: 15px;
                border-radius: 3px;
                transition: $transition;

                &:focus {
                    border-color: $main-color;
                }
            }
            textarea {
                height: auto;
            }
        }
        .form-submit {
            input {
                display: inline-block;
                background: $main-color;
                border: none;
                color: $white-color;
                padding: 8px 30px;
                text-transform: uppercase;
                font-weight: 600;
                outline: 0 !important;
                margin-top: 5px;
                transition: $transition;

                &:hover,
                &:focus {
                    background: $black-color;
                    box-shadow: 0 13px 27px 0 rgba(255, 82, 2, 0.11);
                }
            }
        }
    }
}

/*================================================
404 Error CSS
=================================================*/
.error-content {
    margin: 0 auto;
    max-width: 650px;

    .search-form {
        position: relative;
        margin-top: 45px;

        .search-field {
            display: block;
            width: 100%;
            height: 50px;
            outline: 0;
            box-shadow: unset;
            padding-left: 15px;
            text-transform: capitalize;
            font-weight: 600;
            border: 1px solid #eeeeee;
        }
        .search-submit {
            position: absolute;
            right: 0;
            bottom: 0;
            height: 50px;
            width: 110px;
            outline: 0;
            box-shadow: unset;
            border: none;
            background: $main-color;
            color: $white-color;
            font-weight: 600;
            transition: $transition;

            &:hover,
            &:focus {
                background-color: $black-color;
                color: $white-color;
            }
        }
    }
}
/****************************/
.item-bg1 {
    background-image: url(assets/img/reg-bg.png);
    background-position: -36px -74px;
    background-repeat: no-repeat;
    background-size: 86%;
    background-color: #f7f7f7;
    // border-top:1rem solid #fff;
}

.pb-home {
    padding-top: 9.6rem;
}

.pb-home-1 {
    padding-top: 9.6rem;
    background-image: url(assets/img/curve1.jpeg);
    background-position: center top;
    background-repeat: no-repeat;
    background-size: 50%;
}

.pb-home-2 {
    padding-top: 9.6rem;
    background-image: url(assets/img/curve2.jpeg);
    background-position: center top;
    background-repeat: no-repeat;
    background-size: 50%;
}

.align-items {
    vertical-align: middle;
    align-items: center;
}
.aboutus-sec {
    box-sizing: border-box;
    padding: 16.4rem 0 4rem;
    background-image: url(assets/img/hexagon-bg.jpeg);
    background-position: left 65%;
    background-repeat: no-repeat;
    background-size: 25%;
    h2 {
        text-transform: capitalize;
        color: $main-color;
        font-weight: 700;
        font-size: 3.8rem;
        span {
            color: $red-color;
        }
    }
    h3 {
        font-weight: bold;
        font-size: 2.4rem;
        color: #234a49;
    }
    h6 {
        text-transform: uppercase;
        color: #65677b;
        font-size: 1.6rem;
        letter-spacing: 4px;
    }
    p {
        font-size: 1.2rem;
        // text-transform:lowercase;
    }
}
.grow-testimonials {
    height: auto;
    padding: 4.2rem 0 8.2rem;
    box-sizing: border-box;
    // background: {
    //     image: url(assets/img/bottom-curve.jpeg);
    //     position: 50% 10rem;
    //     repeat: no-repeat;
    //     color: #f7f7f7;
    // }
}
.business-img {
    height: 276px;
    box-sizing: border-box;
    text-align: center;
    color: $white-color;
    padding-top: 4rem;
    background: {
        image: url(assets/img/business_bg.png);
        repeat: no-repeat;
        color: #f7f7f7;
        size: 100%;
    }
    h3 {
        color: #fff;
        font-weight: bold;
    }
    p {
        color: #fff;
    }
}

.bsns-ctnt {
    margin-top: 2.5rem;
    color: #000;
    text-align: center;
    h2 {
        color: #000 !important;
        font-weight: bold !important;
    }
    p {
        color: #64607d;
    }
}

.testimonials {
    padding: 10.1rem 0 2rem;
    text-align: center;
    h3 {
        font-weight: bold;
        font-size: 2.4rem;
        color: #234a49;
        margin-bottom: 3.5rem;
        span {
            color: $red-color;
        }
    }
    h6 {
        text-transform: uppercase;
        color: #65677b;
        font-size: 1.6rem;
        letter-spacing: 4px;
        margin-bottom: 0.5rem;
    }
}

.clients {
    height: auto;
    box-sizing: border-box;
    padding: 8.4rem 0 8rem;
    background-color: #efefef;
    text-align: center;
    h3 {
        font-weight: bold;
        font-size: 2.4rem;
        color: #234a49;
        margin-bottom: 6rem;
        letter-spacing: 3px;
        span {
            color: $red-color;
        }
    }
    h6 {
        text-transform: uppercase;
        color: #65677b;
        font-size: 1.6rem;
        letter-spacing: 4px;
        margin-bottom: 0.5rem;
    }
    img {
        align-items: center;
    }
}

.viewpricing {
    height: auto;
    box-sizing: border-box;
    padding: 8.4rem 0;
    background-color: #004745;
    text-align: center;
    h3 {
        font-weight: 300;
        color: #fff;
        font-size: 2.3rem;
        margin-bottom: 1.5rem;
        display: block;
    }
    h2 {
        display: block;
        font-weight: bolder;
        color: #fff;
        font-size: 3.8rem;
    }
    button.btn-white,
    a.btn-white {
        margin-top: 1rem;
        background: $white-color;
        color: $red-color;
        font-weight: bold;
    }
}

.btn-contact {
    height: 596;
    box-sizing: border-box;
    padding: 15.1rem 0;
    background-color: $main-color;
    text-align: center;
}
/*================================================
Footer CSS
=================================================*/
.footer-area {
    background-color: #efefef;
    padding: {
        bottom: 30px;
    }
    .footer-top {
        img.f-logo {
            //margin-left: 88px;
            width: 220px;
        }
        padding-top: 7rem;
        padding-bottom: 3rem;
        background: {
            image: url(assets/img/footer-bg.jpg);
            position: 30% top;
            repeat: no-repeat;
            color: #fff;
        }
        h5 {
            text-transform: uppercase;
            font-weight: bold;
            color: $main-color;
        }

        ul.footer-links {
            text-align: left;
            li {
                list-style: none;
                text-align: left;
                padding: 0;
                margin: 0;
                display: block;
                a {
                    color: $main-color;
                    width: 100%;
                    height: auto;
                    font-size: 0.9rem;
                    text-align: left;
                    float: left;
                    white-space: nowrap;
                    &:hover {
                        color: $red-color;
                        background: none;
                    }
                }
            }
        }
    }

    .footer-con {
        width: 70%; /*1140px*/
    }
    p {
        color: $white-color;
        font: {
            family: $font-family;
            weight: 500;
        }
        .icofont-heart {
            color: red;
        }
        a {
            display: inline-block;
            color: $white-color;
            font-weight: 600;

            &:hover,
            &:focus {
                color: $main-color;
            }
        }
    }
    ul {
        text-align: right;
        padding: 0;
        list-style-type: none;
        margin: 0;

        li {
            display: inline-block;
            margin-left: 5px;

            a {
                height: 32px;
                width: 32px;
                text-align: center;
                line-height: 32px;
                //transition: $transition;
                color: $main-color;
                border-radius: 50%;
                display: block;
                //background: $white-color;
                font-size: 14px;

                &:hover,
                &:focus {
                    //transform: translateY(-5px);
                    background-color: $main-color;
                    color: $white-color;
                }
            }
        }
    }
}

.f-btm {
    padding: 1.25rem 0 0;
    ul {
        margin-top: 0.25rem;
        float: left;
    }
    .f-txt {
        padding-top: 0.75rem;
        padding-right: 23px;
        color: $main-color;
        a {
            color: $main-color;
            text-decoration: underline !important;
            &:hover {
                color: $red-color;
                text-decoration: none !important;
            }
        }
    }
}

/*================================================
Go Top CSS
=================================================*/
.go-top {
    position: fixed;
    cursor: pointer;
    bottom: 15px;
    right: 15px;
    font-size: 25px;
    color: $white-color;
    background-color: $black-color;
    z-index: 4;
    display: none;
    width: 43px;
    text-align: center;
    height: 45px;
    line-height: 43px;
    border-radius: 5px 5px 0 0;
    transition: $transition;

    &:hover {
        color: $white-color;
        background: $main-color;
    }
}

/*==============================
Sidebar Eaxmple Demo CSS
==============================*/
.demo-modal-panel {
    position: fixed;
    right: 0;
    top: 40%;
    transform: translateY(-40%);
    z-index: 99998;

    .sidebar-demo-control {
        display: inline-block;
        border: none;
        background-color: $main-color;
        color: $white-color;
        padding: 0 0;
        letter-spacing: 1px;
        box-shadow: rgba(0, 0, 0, 0.15) 0 0 25px;
        padding: 12px 0 10px;
        transition: $transition;
        cursor: pointer;
        text-transform: uppercase;
        border-radius: 5px 0 0 5px;
        font: {
            weight: 600;
            size: 13px;
        }
        span {
            writing-mode: vertical-rl;
            text-orientation: upright;
            padding: {
                left: 5px;
                right: 5px;
            }
        }
        &:hover {
            color: $white-color;
            background-color: $black-color;
        }
    }
}
.example-demo-modal {
    position: fixed;
    overflow: hidden;
    right: 0;
    top: 0;
    z-index: 99999;
    transition: 0.9s;
    opacity: 0;
    visibility: hidden;
    border-left: 1px solid #eeeeee;
    right: -100%;

    .inner {
        width: 450px;
        height: 100vh;
        overflow-y: scroll;
        background-color: $white-color;
        box-shadow: 0 0 30px 0 rgba(67, 67, 67, 0.15);
        padding: 90px 30px 30px 30px;

        ul {
            display: flex;
            flex-wrap: wrap;
            padding-left: 0;
            margin-bottom: 0;
            list-style-type: none;
            margin: {
                left: -10px;
                right: -10px;
                bottom: -20px;
            }
            li {
                flex: 0 0 50%;
                max-width: 50%;
                padding: {
                    left: 10px;
                    right: 10px;
                    bottom: 20px;
                }
                .single-demo {
                    position: relative;
                    text-align: center;
                    border-radius: 5px;

                    img {
                        border-radius: 5px;
                        box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
                        border: 5px solid $white-color;
                    }
                    &::before {
                        content: "";
                        position: absolute;
                        left: 0;
                        top: 0;
                        width: 100%;
                        height: 100%;
                        background-color: $main-color;
                        transition: $transition;
                        opacity: 0;
                        visibility: hidden;
                        border-radius: 5px;
                    }
                    span {
                        position: absolute;
                        left: 0;
                        right: 0;
                        top: 50%;
                        transform: translateY(-50%);
                        z-index: 1;
                        color: $white-color;
                        text-transform: capitalize;
                        transition: $transition;
                        opacity: 0;
                        visibility: hidden;
                        margin-top: 15px;
                        font: {
                            size: 16px;
                            weight: 600;
                        }
                    }
                    .link-btn {
                        position: absolute;
                        left: 0;
                        top: 0;
                        width: 100%;
                        height: 100%;
                        display: block;
                        z-index: 3;
                    }
                    &:hover {
                        &::before {
                            opacity: 1;
                            visibility: visible;
                        }
                        span {
                            opacity: 1;
                            visibility: visible;
                            margin-top: 0;
                        }
                    }
                }
            }
        }
    }
    .header-title {
        position: absolute;
        background-color: $white-color;
        top: -1px;
        left: 0;
        right: 0;
        z-index: 5;
        border-bottom: 1px solid #eeeeee;
        padding: {
            top: 18px;
            bottom: 15px;
            left: 30px;
            right: 30px;
        }
        .example-demo-modal-control {
            position: absolute;
            right: 25px;
            top: 15px;
            font-size: 20px;
            color: $black-color;
            transition: $transition;
            background-color: transparent;
            border: none;
            padding: 0;
            display: inline-block;

            &:hover {
                color: $main-color;
            }
        }
        .title {
            color: $black-color;
            margin-bottom: 0;
            font: {
                size: 18px;
                weight: 600;
            }
        }
    }
    &.active {
        right: 0;
        opacity: 1;
        visibility: visible;
    }
}
ul.steps-indicator li.done.navigable .step-indicator {
    color: $main-color !important;
    border: 2px solid $main-color !important;
    font-size: 2px;
}

aw-wizard-navigation-bar.horizontal.large-empty-symbols
    ul.steps-indicator
    li
    .step-indicator {
    top: -40px;
    left: calc(50% - 25px);
    position: absolute;
    width: 35px;
    height: 35px;
    text-align: center;
    vertical-align: middle;
    /* line-height: 87px; */
    transition: 0.25s;
    border-radius: 100%;
    border: 2px solid #e6e6e6;
    color: #e6e6e6;
    z-index: 99;
    display: none;
}
aw-wizard-navigation-bar.horizontal.large-empty-symbols ul.steps-indicator {
    padding: 0px 0 10px 0;
}
.btn-aaamb {
    background-color: #c12228;
    padding: 7px 25px !important;
    min-width: 130px;
    span {
        float: left;
    }
    i {
        float: right;
        margin-top: 5px;
    }
    &:hover {
        background-color: #c12228;
        color: #fff;
    }
}

.prod-info h5 {
    color: #004745;
    margin-left: 10px;
}

.btn-aaamb-1 {
    border-radius: 0.5rem !important;
    background-color: #c12228 !important;
    padding: 3px 5px !important;
    // width: 80%;
    margin-left: 10px;
    i {
        font-size: 0.9rem;
        margin-left: 0.5rem;
    }
    &:hover {
        background-color: #c12228;
        color: #fff;
    }
}
.btn-primary:hover {
    background-color: #c12228 !important;
}
.aaamb-default {
    color: $main-color !important;
}

//Admin-Styles -common styles
app-admin-layout {
    background: #f8f8fb !important;
    #admin-container {
        width: 100vw;
        padding: 10px 20px 20px 230px;
        box-sizing: border-box;
        min-height: 85vh;
        background: #f8f8fb !important;
    }
    h5.NPI-data {
        text-align: right;
        padding-right: 1.25rem;
        font-size: 0.85rem;
        color: #64677b;
    }

    .admin-cont {
        margin: 20px 0px 0px;
        padding: 0;
        .dash-con {
            border: 1px solid #ededed !important;
            border-radius: 15px !important;
            i {
                color: #004745;
            }
            h5 {
                color: $red-color !important;
                font-size: 3.6rem;
                font-weight: bolder;
            }
            h6 {
                color: #64677b;
                font-weight: 300;
            }
        }
    }
}
@media only screen and (max-width: 767px) {
    .main-banner {
        height: 100% !important;
        padding-top: 0px !important;
        padding-bottom: 0px !important;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .main-banner {
        height: 100%;
        padding-top: 0px !important;
        padding-bottom: 0px !important;
    }
}

/*signup wizard styles*/

.auth-full-bg {
    margin-top: 3.8rem;
    h1 {
        color: #004745;
        //text-transform: capitalize;
        line-height: inherit;
    }
}

ul.step-anchor {
    border: 1px solid #f00;
}

.ng-wizard-theme-circles .step-content {
    padding: 10px 0;
    background-color: transparent !important;
    text-align: left;
    width: 87%;
    margin: 0 auto;
    h3 {
        color: $main-color;
        text-align: center;
        font-size: 2.3rem;
    }
    label {
        padding: 10px 10px;
        color: #424242;
        font-size: 14px;
        display: block;

        span {
            color: #c12228;
        }
    }
    .validcol {
        padding: 5px 10px !important;
    }
}
.ng-wizard-theme-circles > ul.step-anchor {
    position: relative;
    background-color: transparent !important;
    border: none;
    list-style: none;
    max-width: 510px;
    margin: 0 auto 40px auto;
}
.ng-wizard-theme-circles .ng-wizard-toolbar {
    background-color: transparent !important;
    padding-left: 10px;
    padding-right: 10px;
    margin-bottom: 0 !important;
}
.ng-wizard-theme-circles > ul.step-anchor > li > a,
.ng-wizard-theme-circles > ul.step-anchor > li > a:hover {
    border: none !important;
    background: #f5f5f5 !important;
    width: 15px !important;
    height: 15px !important;
    text-align: center;
    padding: 0px !important;
    border-radius: 50%;
    box-shadow: 0px 0px 0px 3px #fff !important;
    text-decoration: none;
    outline-style: none;
    z-index: 99;
    color: #bbb;
    line-height: 1;
}
.ng-wizard-theme-circles > ul.step-anchor > li.active > a {
    border-color: none !important;
    color: #fff;
    background: #c12228 !important;
}
.ng-wizard-theme-circles > ul.step-anchor > li.done > a {
    border-color: $main-color !important;
    color: #fff;
    background: $main-color !important;
}

.ng-wizard-theme-circles > ul.step-anchor:before {
    height: 3px !important;
    background-color: #fff !important;
    width: 99% !important;
}

.ng-wizard-theme-circles > ul.step-anchor > li {
    margin-left: 80px !important;
    border: none;
    z-index: 98;
    border: 1px solid #a9aebd;
    background: #fff;
    width: 37px !important;
    height: 37px !important;
    text-align: center;
    padding: 10px !important;
    border-radius: 50%;
    box-shadow: inset 0px 0px 0px 3px #fff !important;
}

.ng-wizard-theme-circles > ul.step-anchor > li:first-child {
    margin-left: 0px !important;
}
.fl {
    float: left;
}
.fr {
    float: right;
}
.custom-align {
    display: flex;
    justify-content: space-evenly;
}

.rounded {
    padding: 0.75rem 1rem !important;
    border-radius: 32px !important;
    border: 1px solid #224a49 !important;
}
.usflag {
    text-align: right;
    width: 90px;
    margin-right: 2rem;
    background: url(assets/img/usflag.png) no-repeat 10px 13px;
}

.signup {
    margin: 1.2rem 0;
    font-size: 1rem;
    input[type="checkbox"] {
        vertical-align: middle;
        padding: 10px;
        width: 20px;
        height: 20px;
        margin-right: 10px;
    }
}

button.custom-large-btn,
a.custom-large-btn {
    font-size: 1.2rem;
    // margin: 0 auto 2rem;
    padding: 1.5rem 5rem;
    background-color: #c12228 !important;
}

button.cst-btn-wt,
button.cst-btn-wt:hover {
    border: 1px solid #bcbcbc;
    box-shadow: none !important;
    background-color: #fff;
    color: #424242;
    //font-size: 1.2rem;
    font-weight: 400;
    img {
        width: 20px;
        margin-left: 1.2rem;
    }
}

button.btn-mw {
    padding: 19px 86px;
    font-size: 16px !important;
}

p.reviewlink {
    margin-top: 3.5rem;
    color: #424242;
    width: 100%;
    a {
        float: right;
        color: $main-color;
        text-decoration: underline !important;
        &:hover {
            text-decoration: none !important;
            color: $red-color;
        }
    }
}

.table-order {
    th {
        background-color: #004745;
        color: #fff;
        padding: 0.9rem;
    }
    th:first-child {
        border-top-left-radius: 0.2rem;
        border-bottom-left-radius: 0.2rem;
    }
    th:last-child {
        border-top-right-radius: 0.2rem;
        border-bottom-right-radius: 0.2rem;
    }
    td {
        padding: 1.3rem;
        font-size: 1rem;
        color: #424242 !important;
    }
    tr:last-child td {
        color: #004745;
        border-bottom: 1px solid #fff !important;
        padding: 1rem;
    }
    tbody {
        padding-bottom: 0px !important;
    }
}

.sub-success {
    text-align: center;
    h3 {
        color: $main-color;
        font-size: 3rem;
        margin-bottom: 1rem;
        margin-top: -2rem;
    }
    h5 {
        color: #424242;
        font-size: 1.5rem;
        span {
            display: block;
        }
    }
    p {
        font-size: 16px;
    }
}
input[type="checkbox"] {
    accent-color: $main-color;
}

select {
    /** for the dropdown indicator */
    appearance: none;
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right 1rem center;
    background-size: 1em;
}

////font styles
.fw-500 {
    font-weight: 500 !important;
}
.font_11_2 {
    font-size: 11.2px !important;
}

.fw-600 {
    font-weight: 600 !important;
}

.fw-700 {
    font-weight: 700 !important;
}

.fw-800 {
    font-weight: 800 !important;
}

.font_18 {
    font-size: 18px !important;
}

/////Margin styles
.m-15 {
    margin: 15px !important;
}
.m-top-5 {
    margin-top: 5px !important;
}
.m-top-7 {
    margin-top: 7px !important;
}
.m-top-10 {
    margin-top: 10px !important;
}
.m-top-12 {
    margin-top: 12px !important;
}
.m-top-13 {
    margin-top: 13px !important;
}
.m-top-14 {
    margin-top: 14px !important;
}
.m-top-15 {
    margin-top: 15px !important;
}
.m-top-18 {
    margin-top: 18px !important;
}
.m-top-20 {
    margin-top: 20px !important;
}
.m-top-24 {
    margin-top: 24px !important;
}
.m-top-28 {
    margin-top: 28px !important;
}
.m-top-30 {
    margin-top: 30px !important;
}
.m-top-35 {
    margin-top: 35px !important;
}
.m-top-45 {
    margin-top: 45px !important;
}
.m-t-3 {
    margin-top: -3px !important;
}
.m-t-5 {
    margin-top: -5px !important;
}
.m-t-6 {
    margin-top: -6px !important;
}
.m-t-10 {
    margin-top: -10px !important;
}
.m-t-15 {
    margin-top: -15px !important;
}
.m-t-18 {
    margin-top: -18px !important;
}
.m-t-20 {
    margin-top: -20px !important;
}
.m-t-35 {
    margin-top: -35px !important;
}
.m-b-12 {
    margin-bottom: -12px !important;
}
.mb-15 {
    margin-bottom: 15px !important;
}
.mb-10 {
    margin-bottom: 10px !important;
}
.mb-20 {
    margin-bottom: 20px !important;
}
.ml-5 {
    margin-left: 5px !important;
}
.ml-8 {
    margin-left: 8px !important;
}
.ml-10 {
    margin-left: 10px !important;
}
.ml-12 {
    margin-left: 12px !important;
}
.ml-14 {
    margin-left: 14px !important;
}
.ml-15 {
    margin-left: 15px !important;
}
.ml-18 {
    margin-left: 18px !important;
}
.ml-20 {
    margin-left: 20px !important;
}
.ml-30 {
    margin-left: 30px !important;
}
.ml-37 {
    margin-left: 37px !important;
}
.ml-50 {
    margin-left: 50px !important;
}
.m-l-10 {
    margin-left: -10px !important;
}
.ml-o {
    margin-left: 0px !important;
}
.mb-o {
    margin-bottom: 0px !important;
}
.m-b-5 {
    margin-bottom: -5px !important;
}
.m-b-10 {
    margin-bottom: -10px !important;
}
.mr-10 {
    margin-right: 10px !important;
}
.m-bottom-30 {
    margin-bottom: 30px !important;
}
////pading styles
.pad_1_5rem {
    padding: 1.5rem !important;
}
.pb-5 {
    padding-bottom: 5px !important;
}

/// float properties
.float_right {
    float: right !important;
}

.float_left {
    float: left !important;
}
/////Width styles//////
.W-50 {
    width: 50% !important;
}
.W-20 {
    width: 20% !important;
}
.W-25 {
    width: 25% !important;
}
.W-80 {
    width: 80% !important;
}

/////////Over ride the styles
///
.was-validated .form-control:invalid,
.form-control.is-invalid {
    background-image: none !important;
}

/////////Global styles//////////////////////

//////Colors for AAAMB//////////////////
.aambgreen_color {
    color: #004745 !important;
}

.aambred_color {
    color: #c12228 !important;
}
.gray_color {
    color: #828893;
}
.white_color {
    color: #fff !important;
}

.text_errors {
    color: #c12228;
    font-weight: 500;
    font-size: 11.2px !important;
    display:flex;
    flex-direction: column;
}

label {
    color: #004745 !important;
}
.custom-large-btn:hover {
    background-color: #004745 !important;
}
.btn_green_color {
    background: #004745;
    color: #fff;
    text-decoration: none !important;
}
.btn_green_color:hover {
    background: $red-color;
    color: #fff;
    text-decoration: none !important;
}
input {
    border: 1px solid $main-color;
}
.bg_standard_color {
    background-color: #004745 !important;
}
.title_header {
    font-size: 1.8rem;
    color: #004745;
    font-weight: bolder;
}
.user_letters {
    margin-left: 11px;
    margin-top: 16px;
    letter-spacing: 1px;
    padding-left: 4px;
}
.user_name_logo {
    color: white;
    border-radius: 50%;
    width: 55px;
    height: 55px;
    font-size: 16px;
    font-weight: 600;
    display: inline-flex;
    vertical-align: middle;
}
.practice_letter_logo {
    color: white;
    border-radius: 50%;
    width: 55px;
    height: 55px;
    font-size: 16px;
    font-weight: 600;
    display: inline-flex;
}
.cursorPointer {
    cursor: pointer !important;
}
.font-large {
    font-size: large !important;
}
.red_color {
    color: #c12228 !important;
}
.breakdown_icon {
    vertical-align: middle !important;
    float: inline-end !important;
}
.bg_greeen {
    background-color: #004745 !important;
}
.display_block {
    display: block !important;
}

.mat-dialog-container{
    background: #ffffff;
}

.cdk-overlay-dark-backdrop {
    background: rgba(0,0,0,.80);
}

.modal-left {
    transform: translateX(37%);
  }

  .offcanvas .offcanvas-end .show {
    width: 40% !important;
  }

  .red-color {
    color: $red-color;
  }

  .form-control:disabled {
    background-color: #e9ecef !important;
    opacity: 1 !important;
    color: #a7a8ad !important;
    border: 1px solid #a7a8ad !important;
}
 
.font-weight-500 {
    font-weight: 500 !important;
}

.min-height-44 {
    min-height: 44vw !important;
}

._color {
    color: #004745;
  }
